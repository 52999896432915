<template>
  <div>
    <div class="newsList_bg">
      <img :src="newsList_bg" />
    </div>
    <div class="newsList_list">
      <div class="newsList_list_table">
        <ul class="newsList_list_table_ul">
          <li
            class="moveTop"
            v-for="item in newsList"
            :key="item.id"
            @click="toDetails(item)"
          >
            <div
              style="
                width: 350px;
                height: 180px;
                overflow: hidden;
                margin-right: 40px;
              "
            >
              <img
                v-if="item.newsCover"
                :src="item.newsCover"
                style="width: 350px; height: 180px"
              />
              <img
                v-else
                src="../../static/image/home_news.png"
                height="180px"
                width="350px"
              />
            </div>
            <div>
              <div class="newsList_list_table_ul_li_title">
                {{
                  item.newsTitle.length > 30
                    ? item.newsTitle.substring(0, 30) + "..."
                    : item.newsTitle
                }}
              </div>
              <div class="newsList_list_table_ul_li_content">
                {{ item.summary ? item.summary : item.title }}
              </div>
              <div class="newsList_list_table_ul_li_time">
                发布时间：{{ item.year }}-{{ item.day }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="newsList_pagination">
      <el-pagination
        layout="prev, pager, next"
        :page-size="6"
        :total="query.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "projectScheduleList",
  data() {
    return {
      newsList_bg: require("../../static/image/newsList_bg.png"),
      newsList: [],
      query: {
        page: 1,
        size: 6,
        total: 0,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
    this.getList(); // 18 文章推荐
  },
  methods: {
    toDetails(item) {
      this.$router.push({
        path: "/newsListDetail",
        query: { id: item.newsId },
      });
    },
    getList() {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?status=PUBLISHED&current=${
            this.query.page
          }&size=6&websiteCategoryId=${18}`
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.newsList = content;
          this.query.total = res.data.data.total;
        });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.newsList_bg {
  img {
    width: 100%;
  }
}

.newsList_list {
  margin: 40px auto;
  display: flex;
  width: 1240px;
  padding: 0 20px;

  .newsList_list_table {
    width: 100%;

    .newsList_list_table_ul {
      padding: 0;
      margin: 0;
      overflow: hidden;

      li {
        margin-top: 20px;
        cursor: pointer;
        list-style-type: none;
        line-height: 62px;
        font-size: 14px;
        font-weight: 400;
        color: #1d1b19;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
        display: flex;
        padding-bottom: 32px;

        .newsList_list_table_ul_li_title {
          font-size: 22px;
          font-family: 宋体;
          font-weight: bold;
          color: #252525;
          line-height: 30px;
        }

        .newsList_list_table_ul_li_content {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #858585;
          line-height: 24px;
          width: 890px;
          overflow: hidden; // 溢出隐藏
          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }

        .newsList_list_table_ul_li_time {
          margin-top: 45px;
          font-size: 12px;
          font-weight: 400;
          color: #abaeb0;
          line-height: 17px;
        }
      }
    }
  }
}

.newsList_pagination {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active + li {
  border: 1px solid #e5e5e5;
}
</style>
